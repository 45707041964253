import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { db, authentication } from '../firebase/firebase-config';

function Header() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(authentication, email, password);
      navigate('/Campaigns'); // Redirect after successful sign-in
    } catch (error) {
      console.error('Error signing in:', error.message);
      alert('Failed to sign in. Please check your email and password.');
    }
  };

  return (
    <header className="flex flex-wrap gap-5 justify-between self-center w-full max-w-[1050px] max-md:max-w-full">
      <h1 className="text-6xl font-semibold text-blue-500 max-md:text-5xl ml-5">
        AdBase
      </h1>
      <nav className="relative my-auto text-3xl font-medium text-neutral-800">
        <button
          onClick={toggleDropdown}
          className="flex items-center gap-2 px-4 py-2 text-grey rounded text-lg bg-transparent hover:bg-blue-500/50 transition-colors duration-300 mr-20"
        >
          Sign In
          <span className="text-m">&#9660;</span>
        </button>
        {isDropdownOpen && (
          <div className="absolute right-20 mt-4 w-80 p-5 bg-white border rounded-lg shadow-xl z-10">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mb-3 px-5 py-2 border rounded-lg text-base"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full mb-3 px-5 py-2 border rounded-lg text-base"
            />
            <button 
              className="w-full mb-3 px-5 py-2 bg-blue-500 text-white rounded-lg text-lg"
              onClick={handleSignIn}
            >
              Sign In
            </button>
            <div className="flex items-center my-3">
              <hr className="flex-grow border-t-2" />
              <span className="mx-6 text-gray-500 text-xl">or</span>
              <hr className="flex-grow border-t-2" />
            </div>
            <div className="flex justify-around text-3xl">
              <span className="text-4xl">&#128100;</span>
              <span className="text-4xl">&#128214;</span>
              <span className="text-4xl">&#128241;</span>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}

export default Header;