import React from 'react';

function Spending() {
  return (
    <div>
      <p>This is the Spending page content.</p>
    </div>
  );
}

export default Spending;