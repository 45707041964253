import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home/Home';
import Campaign from './Campaigns/Campaign';
import MainNav from './MainContent/MainNav';
import RegistrationScreen from './Register/RegistrationScreen';
import { AuthProvider } from './AuthContext'; 

import Distribute from './MainContent/Distribute';
import Analytics from './MainContent/Analytics';
import Spending from './MainContent/Spending';
import Media from './MainContent/Media';
import Settings from './MainContent/Settings';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<RegistrationScreen />} />
          <Route path="/campaigns" element={<Campaign />} />
          <Route path="/main/*" element={<MainLayout />}/>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;

function MainLayout() {
  return (
    <div className="flex h-screen">
      <MainNav />
      <div className="flex-grow">
        <Routes>
          <Route path="distribute" element={<Distribute />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="spending" element={<Spending />} />
          <Route path="media" element={<Media />} />
          <Route path="settings" element={<Settings />} />
        </Routes>
      </div>
    </div>
  );
}