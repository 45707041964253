import React, { useState } from 'react';
import SocialLoginButtons from './SocialLoginButtons';
import { db, authentication } from '../firebase/firebase-config';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function SignUpForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize navigate hook

  const handleRegister = async (event) => {
    event.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      await createUserWithEmailAndPassword(authentication, email, password);
      navigate('/Register'); // Navigate to Register on successful sign up
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <form
      className="flex flex-col items-center py-8 mx-auto w-3/4 rounded-xl bg-stone-50 shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-4 max-md:mt-6 max-md:max-w-full"
      onSubmit={handleRegister}
    >
      <h3 className="text-3xl text-sky-600 mb-4">
        Create new Account!
      </h3>

      <input
        type="email"
        placeholder="Email"
        className="mt-2 w-5/6 max-w-lg h-12 rounded-xl border border-black border-solid bg-stone-50 px-4 text-base"
        aria-label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        className="mt-2 w-5/6 max-w-lg h-12 rounded-xl border border-black border-solid bg-stone-50 px-4 text-base"
        aria-label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        type="password"
        placeholder="Confirm Password"
        className="mt-2 w-5/6 max-w-lg h-12 rounded-xl border border-black border-solid bg-stone-50 px-4 text-base"
        aria-label="Confirm Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />

      <p className="text-red-500 text-center mt-2">{error}</p>

      <button
        type="submit"
        className="mt-4 w-5/6 max-w-lg h-12 bg-blue-500 text-white rounded-xl shadow-[0px_4px_4px_rgba(0,0,0,0.25)] transition-all duration-300 transform hover:bg-blue-600 hover:shadow-lg hover:scale-105 text-base"
        aria-label="Register"
      >
        Register
      </button>
      <p className="self-center mt-4 text-2xl text-center text-blue-500">or</p>
      <SocialLoginButtons />
    </form>
  );
}

export default SignUpForm;