import React from 'react';

function Settings() {
  return (
    <div>
      <p>This is the Settings page content.</p>
    </div>
  );
}

export default Settings;