import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'


const firebaseConfig = {
  apiKey: "AIzaSyC3vt0AVj8ZWmp1gd7UlA9YPFxgscsNcV0",
  authDomain: "adbase-3bb66.firebaseapp.com",
  projectId: "adbase-3bb66",
  storageBucket: "adbase-3bb66.appspot.com",
  messagingSenderId: "994417917138",
  appId: "1:994417917138:web:44a99b3005b4a6ff4dc10b",
  measurementId: "G-0PZQ4S2NQS"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const authentication = getAuth(app);