import React from 'react';

function SocialLoginButtons() {
  const socialButtons = [
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/61d322c92a56b56282ee200bb77f02feb6c4e438712225eacf69a537eceaf590?placeholderIfAbsent=true&apiKey=2d50e1cfad894f548c212aa633a9320f", alt: "Facebook Login" },
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/d19e9d5fb06a8d538669d72b831dd88a71157375d3dc1f58b96fa279cbbbc79e?placeholderIfAbsent=true&apiKey=2d50e1cfad894f548c212aa633a9320f", alt: "Google Login" },
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b77f3a5276e76917b4a99c3a616897e718e51804c2151edb5da89125fdd34c63?placeholderIfAbsent=true&apiKey=2d50e1cfad894f548c212aa633a9320f", alt: "Twitter Login" }
  ];

  return (
    <div className="flex gap-5 self-center mt-7 w-60 max-w-full">
      {socialButtons.map((button, index) => (
        <img
          key={index}
          loading="lazy"
          src={button.src}
          alt={button.alt}
          className="object-contain shrink-0 aspect-[1.02] w-[68px]"
        />
      ))}
    </div>
  );
}

export default SocialLoginButtons;