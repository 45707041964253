import React from 'react';

function CreateNewCard({ createCampaign }) {
  const cardStyle = {
    backgroundColor: '#D3D3D3', // Light grey background
    padding: '20px',            // Padding inside the card
    width: '267.5px',             // Width for the card
    display: 'flex',            // Use flexbox
    justifyContent: 'center',   // Center content horizontally
    alignItems: 'center',       // Center content vertically
    height: '187px',            // Set a fixed height to keep it centered
    transition: 'background-color 0.3s, transform 0.3s', // Smooth transition for hover effects
    cursor: 'pointer',          // Cursor changes to pointer on hover
  };

  const hoverStyle = {
    backgroundColor: '#B0B0B0', // Darker grey on hover
    transform: 'scale(1.02)',   // Slightly enlarge on hover
  };

  const plusStyle = {
    color: 'black',          // Black color for the plus sign
    fontSize: '80px',        // Make the plus sign big
    marginTop: '-5px',       // Adjust margin
  };

  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    createCampaign();
  };

  return (
    <div
      style={isHovered ? { ...cardStyle, ...hoverStyle } : cardStyle}
      className={'shadow-[0px_4px_4px_rgba(0,0,0,0.25)]'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <h3 style={plusStyle}>+</h3>
    </div>
  );
}

export default CreateNewCard;