import React from 'react';
import Header from './Header';
import SignUpForm from './SignUpForm';

// Pre-existing logo URLs for each platform
const logos = [
  { name: 'Facebook', src: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
  { name: 'Instagram', src: 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png' },
  { name: 'YouTube', src: 'https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_icon_%282013-2017%29.png' },
  { name: 'Google', src: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg' },
  { name: 'Pinterest', src: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Pinterest-logo.png' },
  { name: 'LinkedIn', src: 'https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg' },
];

function Home() {
  return (
    <main className="flex overflow-hidden flex-col pt-2 pb-32 bg-white max-md:pb-24">
      <Header />
      <section className="flex flex-col items-center px-26 pt-8 pb-32 mt-2.5 w-full bg-gradient-to-b from-blue-900 to-blue-700 max-md:px-5 max-md:pt-4 max-md:pb-10 max-md:max-w-full">
        <div className="mb-0 w-full max-w-[1050px] max-md:mb-2.5 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col mt-10">
            <div className="flex flex-col w-[75%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full ml-7">
                <h2 className="self-start text-4xl font-medium text-white max-md:text-4xl">
                  What are We?
                </h2>
                <p className="mt-8 text-3xl text-neutral-100 max-md:max-w-full">
                  AdBase is a centralized platform to distribute and manage ads across a wide selection of providers. Intuitively manage multi-platform ad campaigns without the need for multiple dashboards!
                </p>
              </div>
            </div>
            <div className="flex flex-col ml-100 w-[100%] max-md:ml-0 max-md:w-full">
              <SignUpForm />
            </div>
          </div>
        </div>
      </section>
      
      {/* New section for "Platforms Integrated" with sliding animation */}
      <section className="flex flex-col items-center px-26 pt-16 pb-16 mt-0 w-full bg-gray-100 max-md:px-5 max-md:pb-20">
        <h3 className="text-3xl font-semibold text-gray-800 mb-8">Platforms Supported</h3>
        <div className="overflow-hidden w-full max-w-[1300px] relative">
          <div className="flex gap-8 animate-slide-left">
            {logos.map((logo, index) => (
              <div key={index} className="flex-shrink-0 w-36 h-36 flex items-center justify-center">
                <img src={logo.src} alt={`${logo.name} logo`} className="max-h-16 max-w-full" />
              </div>
            ))}
            {/* Duplicating logos to create a seamless loop effect */}
            {logos.map((logo, index) => (
              <div key={`duplicate-${index}`} className="flex-shrink-0 w-36 h-36 flex items-center justify-center">
                <img src={logo.src} alt={`${logo.name} logo`} className="max-h-16 max-w-full" />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Inline CSS for sliding animation */}
      <style jsx>{`
        @keyframes slide-left {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        .animate-slide-left {
          display: flex;
          animation: slide-left 30s linear infinite;
        }
      `}</style>

      {/* Footer Section */}
      <footer className="w-full bg-blue-900 text-white py-6 px-26 max-md:px-5">
        <div className="flex flex-col items-center max-w-[1300px] mx-auto">
          <p className="text-lg">© 2024 AdBase. All rights reserved.</p>
          <div className="flex gap-4 mt-4">
            <a href="/privacy" className="text-white hover:text-gray-300">Privacy Policy</a>
            <a href="/terms" className="text-white hover:text-gray-300">Terms of Service</a>
            <a href="/contact" className="text-white hover:text-gray-300">Contact Us</a>
          </div>
        </div>
      </footer>
    </main>
  );
}

export default Home;