import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faMousePointer, faUser, faDollarSign } from '@fortawesome/free-solid-svg-icons'; // Import the dollar icon

function CampaignCard({ title, spent, budget, images, views, clicks, registers }) {
  const navigate = useNavigate();
  
  const handleCampaignPress = async () => {
    console.log('campaign press');
    navigate('/Main/distribute');
  };

  return (
    <div 
      className="flex flex-col w-[270px] max-md:w-full cursor-pointer transition-colors" 
      onClick={handleCampaignPress}
    >
      <div className="flex flex-col grow max-md:mt-10 border-2 border-gray-300">
        <div className="flex relative items-start py-6 pr-16 pl-7 w-full h-[185px] aspect-[1.486] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-md:px-5 bg-white overflow-hidden hover:bg-gray-100">
          <img 
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b06d341dc9857fb93dbbfc5921ee5b7dcca037fe40542ab022b75e2d68278960?placeholderIfAbsent=true&apiKey=f79c9a33e6b749d8acba3c4826f1530c" 
            alt="" 
            className="object-cover absolute inset-0 w-full h-full opacity-20" 
          />
          <div className="relative flex flex-col flex-grow">
            <h3 className="text-lg font-semibold text-black truncate">{title}</h3>
            <p className="text-sm mt-1 ml-1 text-base font-medium text-black truncate">
              <FontAwesomeIcon icon={faDollarSign} className="mr-2 text-gray-600" /> {spent + ' / ' + budget}
            </p>
            <p className="text-sm mt-1 text-base font-medium text-black truncate">
              <FontAwesomeIcon icon={faEye} className="mr-2 text-gray-600" />
              {views}
            </p>
            <p className="text-sm ml-1 mt-1 text-base font-medium text-black truncate">
              <FontAwesomeIcon icon={faMousePointer} className="mr-3 text-gray-600" />
              {clicks}
            </p>
            <p className="text-sm ml-0.5 mt-1 text-base font-medium text-black truncate">
              <FontAwesomeIcon icon={faUser} className="mr-2.5 text-gray-600" />
              {registers}
            </p>
          </div>
          <div className="flex flex-col gap-2 items-center absolute right-5 top-6"> 
            {images.map((src, index) => (
              <img 
                key={index} 
                loading="lazy" 
                src={src} 
                alt="" 
                className="object-contain shrink-0 aspect-square w-8" 
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignCard;