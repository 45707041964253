import React, { useState, useEffect, useContext } from 'react';
import CampaignCard from './CampaignCard';
import CreateNewCard from './CreateNewCard';
import { setDoc, getDoc, doc, collection, getDocs, addDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/firebase-config';
import { AuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PayPalProvider from '../PayPalProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCheck } from '@fortawesome/free-solid-svg-icons';

function Message({ content }) {
  return <p>{content}</p>;
}

function Campaign() {
  const { user, signOut } = useContext(AuthContext); // Assuming signOut is a method in your AuthContext
  const navigate = useNavigate(); // For navigation

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown visibility
  const [campaignName, setCampaignName] = useState('');
  const [adBudget, setAdBudget] = useState('$5000');
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [userData, setUserData] = useState(''); // Loading state
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false); // Add this line
  
  const [isEditingBudget, setIsEditingBudget] = useState(false); // Add this line
  const [editableBudget, setEditableBudget] = useState(adBudget); // Add this line

  const [message, setMessage] = useState("");

  const [isPayPalModalOpen, setIsPayPalModalOpen] = useState(false);
  const [showPaymentContainer, setShowPaymentContainer] = useState(false); // State to control payment container
  const [addPaymentText, setAddPaymentText] = useState('+ Add a payment method'); // State to manage button text

  useEffect(() => {
    if (user) {
      const campaignsCollection = collection(db, 'users', user.uid, 'campaigns');
      const unsubscribe = onSnapshot(
        campaignsCollection,
        (snapshot) => {
          const campaignList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCampaigns(campaignList);
          setLoading(false); // Set loading to false after fetching
        },
        (error) => {
          console.error('Error fetching campaigns: ', error);
          setLoading(false); // Set loading to false in case of error
        }
      );

      // Cleanup subscription on component unmount
      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        setUserData(userDoc.data()); 
      }
    };
  
    fetchUserData(); // Call the async function
  }, [user]);

  const createCampaign = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const newCampaign = {
      title: campaignName,
      images: [
        'https://cdn.builder.io/api/v1/image/assets/TEMP/37d960f125832145aced74d0d770ce6b8c7059d646e17550652607fd6929a205?placeholderIfAbsent=true&apiKey=f79c9a33e6b749d8acba3c4826f1530c',
        'https://cdn.builder.io/api/v1/image/assets/TEMP/b70be82689f06387a94c8379ed90b0c3638505c340b9f9b0f7c35b8a679d7487?placeholderIfAbsent=true&apiKey=f79c9a33e6b749d8acba3c4826f1530c',
        'https://cdn.builder.io/api/v1/image/assets/TEMP/b610678a1f7be68542c352eedd05fd7a556ccc8bc7196698b867f673e45be0a5?placeholderIfAbsent=true&apiKey=f79c9a33e6b749d8acba3c4826f1530c',
      ],
      spent: 0,
      budget: adBudget,
      views: 0,
      clicks: 0,
      registers: 0,
    };
    await addDoc(collection(db, 'users', user.uid, 'campaigns'), newCampaign, { merge: true });

    setCampaigns([...campaigns, newCampaign]);
    setCampaignName('');
    setAdBudget('');
    closeModal();
  };

  const handleSignOut = async () => {
    await signOut(); 
    navigate('/');
  };

  const initialOptions = {
    "client-id": "AScuacyHwrk4QiSaC92YscgsgKkz_lj-1JUoZe2HDxb5PFAfD4KcohJ6DGmK0cMZ0YgSCaDNFsFJ4_e3",
    "enable-funding": "paylater,venmo,card",
    "disable-funding": "",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };

  // Define the handleSubmit function inside the Campaign component
  const handleSubmit = async () => {
    try {
      const response = await fetch('http://127.0.0.1:5000/api/upload');
      console.log(response);
    } catch (error) {
      console.error('Error during handleSubmit:', error);
    }
  };

  return (
    <div className="main-container">
      <main className="relative z-10 flex flex-col items-start px-9 w-full max-md:px-5 max-md:max-w-full min-h-screen">
        <header className="flex items-center justify-between w-full py-4 max-md:flex-col max-md:items-start">
          <h1 className="text-5xl font-semibold text-blue-500 max-md:text-4xl">AdBase</h1>
          <div className="relative flex items-center gap-4">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              onClick={() => setIsBalanceModalOpen(true)} // Opens the balance modal
            >
              $2378 / $5000
            </button>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc51a4d717d4bf10ceacc9b9b0561863efb834cfbdd2a83a2d8f27245800d145?placeholderIfAbsent=true&apiKey=f79c9a33e6b749d8acba3c4826f1530c"
              alt="Settings"
              className="object-contain w-[35px] h-auto"
            />
            <img
              loading="lazy"
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" // Default profile icon
              alt="Profile"
              className="object-contain w-[45px] h-auto cursor-pointer rounded-full"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            />
            {isDropdownOpen && (
              <div className="absolute right-0 top-14 w-56 bg-gray-100 border rounded shadow-md p-4">
                <button 
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none text-3xl"
                  onClick={() => setIsDropdownOpen(false)}
                >
                  &times;
                </button>
                <img
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" // Default profile icon
                  alt="Profile"
                  className="object-contain w-12 h-12 mx-auto mb-2 rounded-full"
                />
                <div className="px-4 py-2 text-sm font-bold text-center">{userData.companyName}</div>
                <button
                  className="flex items-center w-full px-4 py-2 text-left bg-white hover:bg-gray-200"
                  onClick={handleSignOut}
                >
                  <svg 
                    className="w-5 h-5 mr-2" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth="2" 
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1m0-10V7m0 10h0-2m2 0v-1m0 1v-1m-2-10v10m0-10v-1m0 1h2-2z" 
                    ></path>
                  </svg>
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </header>
        <h2 className="mt-20 ml-20 text-2xl font-bold text-black max-md:mt-10 max-md:ml-2.5">Campaigns</h2>
        <section className="mt-9">
          <div className=" ml-20 flex flex-wrap gap-5 max-md:flex-col">
            <CreateNewCard createCampaign={createCampaign} />
            {loading ? (
              <div className="loader">Loading...</div> // Loading indicator
            ) : (
              campaigns.map((campaign, index) => (
                <CampaignCard key={index} {...campaign} />
              ))
            )}
          </div>
        </section>
      </main>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '63vh',
          backgroundColor: '#BBCDE5',
          zIndex: 0,
        }}
      ></div>

      {isModalOpen && (
        <div className="fixed inset-0 flex justify-end z-50">
          <div
            className="modal-overlay fixed inset-0 bg-black opacity-50 transition-opacity"
            onClick={closeModal}
          ></div>
          <div
            className={`modal-content fixed top-0 right-0 h-full w-full max-w-lg bg-white p-10 transform transition-transform ease-in-out duration-300 ${
              isModalOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <button className="text-4xl font-bold absolute top-5 right-5 focus:outline-none" onClick={closeModal}>
              &times;
            </button>
            <h2 className="text-3xl font-bold mb-6 mt-10">Create New Campaign</h2>
            <form onSubmit={handleFormSubmit}>
              <div className="mb-4">
                <label className="block text-lg font-medium mb-2">Campaign Name</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-lg font-medium mb-2">Campaign Budget</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={adBudget}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                    setAdBudget(`$${value}`);
                  }}
                  required
                />
              </div>
              <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                Save Campaign
              </button>
            </form>
          </div>
        </div>
      )}
      {isBalanceModalOpen && (
        <div className="fixed inset-0 flex justify-start z-50">
          <div
            className="modal-overlay fixed inset-0 bg-black opacity-50 transition-opacity"
            onClick={() => setIsBalanceModalOpen(false)}
          ></div>
          <div className="flex">
            <div
              className={`modal-content fixed top-0 left-0 h-full w-full max-w-lg bg-white p-10 transform transition-transform ease-in-out duration-300 ${
                isBalanceModalOpen ? 'translate-x-0' : '-translate-x-full'
              }`}
            >
              <button
                className="text-4xl font-bold absolute top-5 right-5 focus:outline-none"
                onClick={() => setIsBalanceModalOpen(false)}
              >
                &times;
              </button>
              <div className="text-center mb-6">
                <div className="flex justify-center items-center mb-6 space-x-6">
                  <div className="text-center">
                    <p className="text-3xl font-bold text-gray-800 mb-1">Spent</p>
                    <p className="text-5xl font-semibold text-blue-500">$2367</p>
                  </div>
                  <div className="border-l border-gray-300 h-20"></div>
                  <div className="text-center mb-1">
                    <p className="text-3xl font-bold text-gray-800 mb-1">Budget</p>
                    <div className="flex items-center justify-center">
                      {isEditingBudget ? (
                        <input
                          type="text"
                          className="text-4xl font-semibold text-gray-400 border-2 border-blue-500 rounded w-40 text-center"
                          value={editableBudget}
                          onChange={(e) => setEditableBudget(e.target.value)}
                          autoFocus
                        />
                      ) : (
                        <p className="text-5xl font-semibold text-gray-400">{adBudget}</p>
                      )}
                      <button
                        className="ml-2"
                        onClick={() => {
                          if (isEditingBudget) {
                            setAdBudget(editableBudget); // Save the new budget
                            setIsEditingBudget(false); // Exit edit mode
                          } else {
                            setEditableBudget(adBudget); // Set editable field with the current budget
                            setIsEditingBudget(true); // Enter edit mode
                          }
                        }}
                      >
                        {isEditingBudget ? (
                          <FontAwesomeIcon icon={faCheck} className="w-6 h-6 text-green-500" />
                        ) : (
                          <FontAwesomeIcon icon={faPencilAlt} className="w-6 h-6 text-blue-500" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-10 px-6 py-8 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-400"
                  onClick={() => {
                    setShowPaymentContainer(!showPaymentContainer); // Toggle PayPal container
                    setAddPaymentText(showPaymentContainer ? '+ Add a payment method' : 'Adding...'); // Toggle text
                  }}
                >
                  <p className="text-blue-500 text-lg font-semibold">{addPaymentText}</p>
                </div>
              </div>
              <p className="text-xs text-gray-500 mb-4">
                All expenses are managed by this budget. Once the budget is reached, all paid services will stop.
              </p>
              <Message content={message} />
            </div>
            
            {showPaymentContainer && (
              <div className="fixed top-0 left-[calc(512.5px)] h-full w-[300px] bg-gray-100 p-5 transform transition-transform ease-in-out duration-300 overflow-y-auto">
                <button
                  className="text-2xl font-bold absolute top-5 right-5 focus:outline-none"
                  onClick={() => {
                    setAddPaymentText('+ Add a payment method');
                    setShowPaymentContainer(false);
                  }}
                >
                  &times;
                </button>
                <h2 className="text-xl font-bold mb-4">Add Payment Method</h2>
                <div className="text-center">
                  <PayPalProvider/>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Campaign;