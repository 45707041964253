import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router

function MainNav() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/campaigns'); // Replace with the actual route to go back to campaigns
  };

  return (
    <div className="flex h-screen">
      <aside className="w-64 bg-white text-gray-800 p-5 space-y-6 border-r-2 border-gray-200 relative">
        <div
          className="flex items-center cursor-pointer mb-0"
          onClick={handleGoBack}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-600 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
          <span className="text-sm font-semibold text-gray-600">
            Go Back to Campaigns
          </span>
        </div>

        <div className="flex justify-center mt-0">
          <h1 className="text-5xl font-semibold text-blue-500 max-md:text-4xl">
            AdBase
          </h1>
        </div>

        <nav className="mt-10">
          <ul className="space-y-4">
            <li>
              <a href="/Main/distribute" className="block py-2 px-4 rounded hover:bg-gray-100">
                Distribute
              </a>
            </li>
            <li>
              <a href="/Main/analytics" className="block py-2 px-4 rounded hover:bg-gray-100">
                Analytics
              </a>
            </li>
            <li>
              <a href="/Main/spending" className="block py-2 px-4 rounded hover:bg-gray-100">
                Spending
              </a>
            </li>
            <li>
              <a href="/Main/media" className="block py-2 px-4 rounded hover:bg-gray-100">
                Media
              </a>
            </li>
            <li>
              <a href="/Main/settings" className="block py-2 px-4 rounded hover:bg-gray-100">
                Settings
              </a>
            </li>
          </ul>
        </nav>
      </aside>

    </div>
  );
}

export default MainNav;