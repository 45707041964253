import React from 'react';

function Distribute() {
  return (
    <div className="flex flex-col relative">
      <div className="flex justify-between items-center w-full bg-gray-100 border-b border-gray-300 py-2">
        <div className="flex">
          <div className="flex flex-col items-center text-center mx-2">
            <span className="text-rg font-semibold text-gray-700">Impressions</span>
            <span className="text-s text-gray-600">1,234</span>
          </div>
          <div className="flex flex-col items-center text-center mx-2">
            <span className="text-rg font-semibold text-gray-700">Clicks</span>
            <span className="text-s text-gray-600">567</span>
          </div>
          <div className="flex flex-col items-center text-center mx-2">
            <span className="text-rg font-semibold text-gray-700">Conversions</span>
            <span className="text-s text-gray-600">89</span>
          </div>
          <div className="flex flex-col items-center text-center mx-2">
            <span className="text-rg font-semibold text-gray-700">CTR</span>
            <span className="text-s text-gray-600">4.6%</span>
          </div>
        </div>
        <div className="flex items-center">
          <img
            src="https://via.placeholder.com/40"
            alt="Profile"
            className="w-10 h-10 rounded-full border border-gray-300 mx-2"
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center p-6">
        <p>Advertiser Overview</p>
      </div>

      <div className="flex justify-center items-center">
        <div className="w-full lg:w-2/3 xl:w-1/2 bg-white shadow-md rounded-lg p-4 overflow-y-auto max-h-96">
          <div className="grid gap-4">
            {Array.from({ length: 10 }).map((_, index) => (
              <button
                key={index}
                className="w-full bg-blue-100 hover:bg-blue-200 text-blue-700 font-semibold py-4 rounded-lg shadow-md transition duration-200"
              >
                Provider {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Distribute;